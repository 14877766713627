import Cookies from "universal-cookie";
import axios from "axios";
import mem from "mem";

// // Dynamic function to construct base URL
// async function getBaseUrl() {
//     const currentDomain = window.location.hostname;
//     const protocol = window.location.protocol;

//     // Replace "api.bost64.obuh.info" with current domain's API subdomain dynamically
//     const baseURL = `${protocol}//${currentDomain}/api`;

//     return baseURL;
// }

const axiosInstance = axios.create();
axiosInstance.defaults.timeout = 10000; // Adjusted to a reasonable timeout

// // Interceptor to set the dynamic baseURL for each request
// axiosInstance.interceptors.request.use(
//     async (config) => {
//         config.baseURL = await getBaseUrl();
//         return config;
//     },
//     (error) => Promise.reject(error)
// );


// Set common headers
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';

global.Buffer = global.Buffer || require("buffer/").Buffer;

const cookies = new Cookies();
// axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
// axios.defaults.baseURL = "https://api.smng.fun/api/";
// axios.defaults.baseURL = `${window.location.protocol}//${window.location.hostname}/api/`
if (window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1') {
  axios.defaults.baseURL = 'https://bost64.mamoth.club/api/'
} else {
  axios.defaults.baseURL = `${window.location.protocol}//${window.location.hostname}/api/`
}


const axiosPublic = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const hostName = window.location.host;

export const axiosPayment = axios.create({
  baseURL: "https://api-nameserve.growgold.xyz/",
  redirect: "follow",
  headers: {
    "Content-type": "application/json",
    "x-host-name": hostName,
  },
});

const refreshTokenFn = async () => {
  const token = cookies.get("token");

  try {
    const response = await axiosPublic.post(
      "/auth/refresh",
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const newToken = response?.data?.access_token;

    if (!newToken) {
      cookies.remove("token", { path: "/" });
      cookies.remove("user", { path: "/" });
      cookies.remove("token_expires_in", { path: "/" });
      window.location.href = "/";
    }
    cookies.set("token", newToken, { path: "/" });
    console.log("Token refreshed!");

    return newToken;
  } catch (error) {
    cookies.remove("token", { path: "/" });
    cookies.remove("user", { path: "/" });
    cookies.remove("token_expires_in", { path: "/" });
    window.location.href = "/";
  }
};

const maxAge = 10000;

const memoizedRefreshToken = mem(refreshTokenFn, { maxAge });

// This interceptor adds authorization token to requests
axios.interceptors.request.use(
  async (request) => {
    const token = cookies.get("token");
    if (token) {
      request.headers.common.Authorization = token;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

// The interceptor refreshes token if about to expire
axios.interceptors.response.use(
  async (response) => {
    if (response?.data?.error === "Unauthenticated.") {
      cookies.remove("token", { path: "/" });
      cookies.remove("user", { path: "/" });
      cookies.remove("token_expires_in", { path: "/" });
      window.location.href = "/";
      return;
    }

    if (!response?.config?.headers?.Authorization) {
      cookies.remove("token", { path: "/" });
      cookies.remove("user", { path: "/" });
      cookies.remove("token_expires_in", { path: "/" });
      return response;
    }

    const token = cookies.get("token");
    const buff = Buffer.from(token.split(".")[1], "base64");
    const tokenCreationStamp = JSON.parse(buff.toString()).nbf * 1000;
    const tokenExpiresIn = cookies.get("token_expires_in") * 1000; //3600000
    // const tokenExpiresIn = cookies.get("token_expires_in") * 1000 / 60;  // 60 sec
    const now = Date.now();
    const timeLeft = tokenExpiresIn - (now - tokenCreationStamp);

    // if (now - lastRefresh < tokenExpiresIn / 2) {
    // 	return response; }

    console.log("Token left time: " + timeLeft / 1000 + " sec");

    if (timeLeft < 0) {
      cookies.remove("token", { path: "/" });
      cookies.remove("user", { path: "/" });
      cookies.remove("token_expires_in", { path: "/" });
      window.location.href = "/";
      return;
    } else if (timeLeft < tokenExpiresIn / 3) {
      const result = await memoizedRefreshToken();
      if (result) {
        const newToken = `Bearer ${result}`;
        cookies.set("token", newToken, { path: "/" });
      } else {
        console.log("Failed refresh!!");
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });
        cookies.remove("token_expires_in", { path: "/" });
        window.location.href = "/";
      }
    }

    return response;
  },
  async (error) => {
    const config = error?.config;
    if (error?.response?.status === 401 && !config?.sent) {
      cookies.remove("token", { path: "/" });
      cookies.remove("user", { path: "/" });
      cookies.remove("token_expires_in", { path: "/" });
    }
    return Promise.reject(error);
  }
);

export default axios;
